import { css } from 'emotion';

export const orderCompleteContainer = css`
  text-align: center;

  img {
    width: 126px;
    height: 126px;
    object-fit: scale-down;
    margin: 29px auto 0;
  }

  h2,
  h1,
  p {
    color: var(--gray-700);
  }

  h2 {
    margin-top: 24px;
  }

  h1 {
    margin-top: 40px;
  }

  p {
    max-width: 500px;
    margin: 40px auto 0;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto 0;
    > * + * {
      margin-left: 12px;
    }
  }

  @media (min-width: 768px) {
    margin: 34px auto 0;
  }
`;
