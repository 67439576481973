import Cart from './cart';
import Listing from './listing';
import OrderComplete from './order-complete';
import React from 'react';
import { Router } from '@reach/router';
import Store from '.';
import { withPrefix } from 'router';

const App = () => (
  <Router>
    <OrderComplete path={withPrefix('/store/:id/order-complete/:orderNumber')} />
    <Cart path={withPrefix('/store/:id/cart')} />
    <Store path={withPrefix('/store/:id')}></Store>
    <Listing path={withPrefix('/store/:id/listing/:listingId')} />
  </Router>
);

export default App;
