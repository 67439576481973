import { Button } from 'antd';
import Link from 'components/Link';
import React from 'react';
import { orderCompleteContainer } from './styles';
import { uris } from 'router';

const { storeUri, browseStoresUri } = uris;

const OrderComplete = ({
  orderNumber = 0,
  currentStoreDisplayArgs = {},
  imageUrl = '',
  name = '',
} = {}) => (
  <div className={orderCompleteContainer}>
    <img src={imageUrl} loading="lazy" />

    <h2>{name}</h2>

    <h1>Thanks for your order!</h1>

    <p className="regular-plus">
      Your order has been placed - #{orderNumber}. We’ve emailed you a confirmation.
      Please keep an eye out for another email from the seller once they have confirmed
      it.
    </p>

    <div className="button-container">
      <Link to={storeUri(currentStoreDisplayArgs)}>
        <Button>RETURN TO STORE</Button>
      </Link>
      <Link to={browseStoresUri()}>
        <Button type="primary">BROWSE STORES</Button>
      </Link>
    </div>
  </div>
);

export default OrderComplete;
